@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  line-height: 1rem;
  max-width: 1920px;
  margin: 0 auto !important;
}

.Mui-selected {
  border: 1px solid #333 !important;
}

.menu-item:hover {
  background: #fff !important;
}

.paper-drawer {
  margin-right: 0;
}

.MuiFormHelperText-root {
  margin-left: 0px !important;
  color: #5F6A80 !important;
}

.payment-modal {
  min-width: 320px;
  max-width: 550px;
}

.payment-modal-header {
  padding: 1rem;
}

.payment-modal-content {
  padding: 1rem;
  font-size: 12px;
}

.subtotal-statement {
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
}

#paymentMode {
  font-size: 14px;
  margin: 0 0 1rem;
}

.payment-reimbursement {
  margin: 1rem 0 0;
}

.payment-form-web {
  display: none !important;
}

.payment-radio-buttons {
  justify-content: center;
  margin-top: 10px;
}

p {
  line-height: 1.2rem;
}

.nudge-title {
  font-size: 18px;
  font-weight: 600;
}

.nudge-description {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}

.change-user {
  font-weight: 600;
  font-size: 12px;
  color: var(--color-primary);
  margin: 30px 0px;
  display: block;
  cursor: pointer;
  text-decoration: underline;
  padding: 0px 1rem 50px;
}

@media screen and (min-width: 800px) {
  body {
    background: #fff;
    line-height: 1.5rem;
  }
  .paper-drawer {
    margin-left: auto;
    margin-right: 5.5%;
    left: initial !important;
    width: 500px;
  }
  .payment-modal {
    min-width: 600px;
    max-width: 850px;
  }
  .payment-modal-header {
    padding: 20px 30px;
  }
  .payment-modal-content {
    padding: 20px 30px;
    font-size: 14px;
  }
  .subtotal-statement {
    margin: 1.25rem 0;
  }
  #paymentMode {
    font-size: 16px;
    margin: 0 0 1.5rem;
  }
  .payment-reimbursement {
    margin: 1rem 0;
  }
  .payment-form-web {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row !important;
    align-items: center;
    display: inline-flex !important;
  }
  .payment-radio-buttons {
    justify-content: flex-end;
    margin-top: 0px;
  }
  .nudge-title {
    font-size: 20px;
  }
  .nudge-description {
    font-size: 16px;
  }
  .change-user {
    padding: 0px;
  }
}
